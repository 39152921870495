import { format } from 'react-string-format';

const config = {
    host: process.env.REACT_APP_PASSPORT_HOST,
    url: {
        "login": "cas/login",
        "logout": "cas/logout",
    },
    apis: {
        "login": {
            method: "post",
            path: "cas/login",
        },
        "logout": {
            method: "post",
            path: "cas/logout/{0}",
        },
        "getTicketByRootTicket": {
            method: "get",
            path: "tickets/root_ticket/{0}",
        },
        "getUserInfoByTicket": {
            method: "get",
            path: "users/ticket/{0}",
        },
        "getUserInfoByRootTicket": {
            method: "get",
            path: "users/root_ticket/{0}",
        },
        "changeUserPassword": {
            method: "put",
            path: "users/{0}/password",
        },
        "resetUserPassword": {
            method: "post",
            path: "users/reset_password",
        },
        "changeUserInfo": {
            method: "put",
            path: "users/{0}",
        },
        "register": {
            method: "post",
            path: "register"
        },
        "sendRegisterVerifyCode": {
            method: "post",
            path: "register/send_register_verify_code/{0}"
        },
        "sendUpdateUserVerifyCode": {
            method: "post",
            path: "users/send_update_user_verify_code/{0}"
        },
        "sendResetPasswordVerifyCode": {
            method: "post",
            path: "users/send_reset_password_verify_code/{0}"
        },
    },
}
export function getUrl(name) {
    let url = config.url[name];
    return url ? config.host.trimRight("/") + "/" + url : "";
}
export function getApi(api, ...args) {
    let apiConfig = config.apis[api];
    let urlTemplate = config.host.trimRight("/") + "/" + apiConfig.path;
    return {
        method: apiConfig.method,
        url: format(urlTemplate, ...args),
    }
};
