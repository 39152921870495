import { Link, useHistory } from "react-router-dom";
import { Container, Form, Alert, InputGroup, FormCheck, Button, Spinner, Modal } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { sendRegisterVerifyCode, register } from "../../api/user";
import { useState } from "react";
import "./index.scss";

// 发送短信间隔，单位秒
const sendVerifyCodePeriod = 60;

export default function Register(props) {
    let history = useHistory();
    let [remaining, setRemaining] = useState(sendVerifyCodePeriod);
    return (
        <div className="register-bg">
            <Container className="register-container">
                <Formik
                    onSubmit={(values, actions) => {
                        registerUser(values, actions, history);
                    }}
                    validationSchema={formValidation()}
                    initialValues={{
                        username: '',
                        phone: '',
                        verifyCode: '',
                        name: '',
                        password: '',
                        userPrivacy: true,
                    }}
                >
                    {(props) => {
                        return formBody(props, remaining, setRemaining);
                    }}
                </Formik>
            </Container>
        </div>
    )
}

function formValidation() {
    return Yup.object().shape({
        username: Yup
            .string()
            .required('请输入账号')
            .min(6, '账号至少6个字符')
            .max(50, '账号至多50个字符'),
        phone: Yup
            .string()
            .required('请输入手机号')
            .matches(/^1\d{10}$/, '手机号格式不正确'),
        verifyCode: Yup
            .string()
            .required('请输入验证码'),
        name: Yup
            .string()
            .required('请输入账号名称')
            .min(3, '账号名称至少3个字符')
            .max(50, '账号名称至多50个字符'),
        password: Yup
            .string()
            .required('请输入密码')
            .min(8, '密码至少8个字符')
            .max(24, '密码至多24个字符'),
        userPrivacy: Yup
            .bool()
            .oneOf([true], '请阅读并同意蜂莲企服用户协议'),
    });
}

function formBody({ values, initialValues, isSubmitting, touched, validateField, setStatus, status, handleSubmit, handleChange, errors }, remaining, setRemaining) {
    let getRegisterBtn = function () {
        let registerBtn;
        if (isSubmitting) {
            registerBtn = (<>
                <Spinner
                    animation="border"
                    size="sm"
                    style={{
                        marginRight: "5px",
                        display: !isSubmitting ? "none" : "",
                    }} />
                <span>注册中</span>
            </>)
        } else {
            registerBtn = <span>注册</span>
        }
        return registerBtn;
    }
    let canSendVerifyCode = function () {
        return initialValues.phone !== values.phone &&
            !isSubmitting &&
            remaining === sendVerifyCodePeriod &&
            !errors.phone;
    }
    let getSendText = function () {
        let sendText = "发送验证码";
        if (remaining !== sendVerifyCodePeriod) {
            sendText = remaining + "秒后发送";
        }
        return sendText;
    }
    return (
        <Modal.Dialog className="register-form">
            <Modal.Header>
                <Modal.Title>注册新用户</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                    {status && !status.success && <Alert variant="danger">
                        {status.message}
                    </Alert>
                    }
                    <Form.Group className="mb-3">
                        <Form.Label>账号</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                name="username"
                                value={values.username}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.username && !!errors.username}
                                maxLength="50"
                            />
                            <Form.Control.Feedback tooltip type="invalid">
                                {errors.username}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>手机号</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={values.phone}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.phone && !!errors.phone}
                                maxLength="11"
                            />
                            <Button
                                type="button"
                                variant="secondary"
                                disabled={!canSendVerifyCode()}
                                onClick={() => { validateField('phone') && canSendVerifyCode() && sendVerifyCode(values.phone, remaining, setStatus, setRemaining) }}>
                                {getSendText()}
                            </Button>
                            <Form.Control.Feedback tooltip type="invalid">
                                {errors.phone}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>验证码</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                name="verifyCode"
                                value={values.verifyCode}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.verifyCode && !!errors.verifyCode}
                                maxLength="6"
                            />
                            <Form.Control.Feedback tooltip type="invalid">
                                {errors.verifyCode}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>账号名称</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                name="name"
                                value={values.name}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.name && !!errors.name}
                                maxLength="50"
                            />
                            <Form.Control.Feedback tooltip type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        </InputGroup>
                        <Form.Text muted>企业名称或个人昵称</Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>密码</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="password"
                                name="password"
                                value={values.password}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.password && !!errors.password}
                                maxLength="24"
                            />
                            <Form.Control.Feedback tooltip type="invalid">
                                {errors.password}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <InputGroup hasValidation>
                            <FormCheck>
                                <FormCheck.Input
                                    type="checkbox"
                                    name="userPrivacy"
                                    defaultChecked={values.userPrivacy}
                                    onChange={handleChange}
                                    isInvalid={touched.userPrivacy && !!errors.userPrivacy}
                                />
                                <FormCheck.Label className="user-privacy">
                                    我已阅读并同意 <Link to="/agreement">《蜂莲企服用户协议》</Link>
                                </FormCheck.Label>
                                <Form.Control.Feedback tooltip type="invalid">
                                    {errors.userPrivacy}
                                </Form.Control.Feedback>
                            </FormCheck>
                        </InputGroup>
                    </Form.Group>
                    <div className="d-grid">
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                            {getRegisterBtn()}
                        </Button>
                    </div>
                    <Link className="login-entry" to="/login">已经有账号？点这里登录</Link>
                </Form>
            </Modal.Body>
        </Modal.Dialog>
    )
}

function sendVerifyCode(phone, remaining, setStatus, setRemaining) {
    let sec = remaining;
    sendRegisterVerifyCode(phone, result => {
        setStatus(result);
        if (!result.success) {
            console && console.log(result.message);
            return;
        }
        let timer = setInterval(() => {
            if (sec > 1) {
                setRemaining(--sec);
            } else {
                clearInterval(timer);
                setRemaining(sendVerifyCodePeriod);
            }
        }, 1000);
    });
}

function registerUser(userInfo, actions, history) {
    register(userInfo, result => {
        actions.setStatus(result);
        if (!result.success) {
            console && console.log(result.message);
            return;
        }
        setTimeout(() => {
            history.push("/login");
        }, 1000);
    }, error => {
        console && console.log(error);
    }, () => {
        actions.setSubmitting(false);
    });
}