import React from "react";
import { Alert, Button, Modal, Form, InputGroup, Spinner } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { changeUserPassword } from "../../api/user";
import { getCookieStoredUserInfo } from "../../api/cookie";

export default class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }
    changePassWord(values, actions, closeHandler) {
        let cookieStoredUser = getCookieStoredUserInfo();
        let userId = cookieStoredUser?.id;
        if (!userId) {
            actions.setSubmitting(false);
            return;
        }
        changeUserPassword(userId, { ...values, userId: userId }, result => {
            actions.setStatus(result);
            if (!result.success) {
                console && console.log(result.message);
                return;
            }
            setTimeout(() => {
                closeHandler();
            }, 1000);
        }, error => {
            console && console.log(error);
        }, () => {
            actions.setSubmitting(false);
        });
    }
    formValidation() {
        return Yup.object().shape({
            oldPassword: Yup
                .string()
                .required('请输入当前密码'),
            newPassword: Yup
                .string()
                .required('请输入新密码')
                .min(8, '密码至少8个字符')
                .max(24, '密码至多24个字符')
                .notOneOf([Yup.ref('oldPassword')], '新密码不能和旧密码相同'),
            confirmPassword: Yup
                .string()
                .required('请重复输入新密码')
                .oneOf([Yup.ref('newPassword')], '两次输入的新密码不同'),
        });
    }
    formBody({ values, isSubmitting, touched, status, handleSubmit, handleChange, errors }) {
        let submitBtn;
        if (isSubmitting) {
            submitBtn = (<>
                <Spinner
                    animation="border"
                    size="sm"
                    style={{
                        marginRight: "5px",
                        display: !isSubmitting ? "none" : "",
                    }} />
                <span>修改中</span>
            </>)
        } else {
            submitBtn = <span>确认修改</span>
        }
        return (
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>修改密码</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {status && <Alert variant={status.success ? "success" : "danger"} >
                        {status.message}
                    </Alert>
                    }
                    <Form.Group className="mb-3">
                        <Form.Label>旧密码</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="password"
                                name="oldPassword"
                                value={values.oldPassword}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.oldPassword && !!errors.oldPassword}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.oldPassword}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>新密码</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="password"
                                name="newPassword"
                                value={values.newPassword}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.newPassword && !!errors.newPassword}
                                maxLength="24"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.newPassword}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>确认新密码</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="password"
                                name="confirmPassword"
                                value={values.confirmPassword}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                                maxLength="24"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.confirmPassword}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.closeHandler} disabled={isSubmitting}>取消</Button>
                    <Button type="submit" variant="primary" disabled={isSubmitting}>
                        {submitBtn}
                    </Button>
                </Modal.Footer>
            </Form>
        )
    }
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.closeHandler}
                backdrop="static"
                keyboard={false}
                size="sm"
            >
                <Formik
                    onSubmit={(values, actions) => {
                        this.changePassWord(values, actions, this.props.closeHandler)
                    }}
                    validationSchema={this.formValidation()}
                    initialValues={{
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                    }}
                >
                    {(props) => {
                        return this.formBody(props);
                    }}
                </Formik>
            </Modal>
        )
    }
}
