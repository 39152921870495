import Cookies from 'js-cookie';

export function getCookieStoredUserInfo() {
    let cookieStoredUser = Cookies.get('user');
    if (cookieStoredUser) {
        return JSON.parse(cookieStoredUser);
    }
    return null;
}

export function updateCookieStoredUserInfo(user) {
    Cookies.set('user', JSON.stringify(user), {
        expires: 7, // 7 天有效期
    });
}

export function storeRootTicket(rootTicket, age) {
    let days = Math.floor(age / 60 / 60 / 24);
    Cookies.set('root_ticket', rootTicket, { expires: days });
}

export function getRootTicket() {
    return Cookies.get('root_ticket');
}

export function setLoginUserCookie(rootTicket, age, user) {
    storeRootTicket(rootTicket, age);
    updateCookieStoredUserInfo(user);
}

export function avoidUserCookie() {
    Cookies.remove('user');
    Cookies.remove('root_ticket');
}
