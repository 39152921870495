import axios from "axios";
import { getRootTicket } from "./cookie";
import { getApi } from "../config/api";

export function login(credential, successCallback, errorCallback = null, finallyCallback = null) {
    let api = getApi("login");
    axios({ ...api, data: { ...credential } }).then(response => {
        successCallback(response.data);
    }).catch(errorCallback).finally(finallyCallback);
}

export function logout(successCallback, errorCallback = null, finallyCallback = null) {
    let api = getApi("logout", getRootTicket());
    axios({ ...api }).then(response => {
        response.data?.success && successCallback(response.data);
    }).catch(errorCallback).finally(finallyCallback);
}

export function getUserInfoByTicket(ticket, successCallback, errorCallback = null, finallyCallback = null) {
    let api = getApi("getUserInfoByTicket", ticket);
    getUserInfo(api, successCallback, errorCallback, finallyCallback);
}

export function getUserInfoByRootTicket(rootTicket, successCallback, errorCallback = null, finallyCallback = null) {
    let api = getApi("getUserInfoByRootTicket", rootTicket);
    getUserInfo(api, successCallback, errorCallback, finallyCallback);
}

function getUserInfo(api, successCallback, errorCallback = null, finallyCallback = null) {
    axios(api).then(response => {
        successCallback(response.data);
    }).catch(errorCallback).finally(finallyCallback);
}

export function getTicketByRootTicket(rootTicket, successCallback, errorCallback = null, finallyCallback = null) {
    let api = getApi("getTicketByRootTicket", rootTicket);
    axios(api).then(response => {
        successCallback(response.data);
    }).catch(errorCallback).finally(finallyCallback);
}

export function register(userInfo, successCallback, errorCallback = null, finallyCallback = null) {
    let api = getApi("register");
    axios({ ...api, data: { ...userInfo } }).then(response => {
        successCallback(response.data);
    }).catch(errorCallback).finally(finallyCallback);
}

export function changeUserPassword(userId, userPassword, successCallback, errorCallback = null, finallyCallback = null) {
    let api = getApi("changeUserPassword", userId);
    axios({ ...api, data: { ...userPassword } }).then(response => {
        successCallback(response.data);
    }).catch(errorCallback).finally(finallyCallback);
}

export function resetUserPassword(newPassword, successCallback, errorCallback = null, finallyCallback = null) {
    let api = getApi("resetUserPassword");
    axios({ ...api, data: { ...newPassword } }).then(response => {
        successCallback(response.data);
    }).catch(errorCallback).finally(finallyCallback);
}

export function changeUserInfo(userId, userInfo, successCallback, errorCallback = null, finallyCallback = null) {
    let api = getApi("changeUserInfo", userId);
    axios({ ...api, data: { ...userInfo } }).then(response => {
        successCallback(response.data);
    }).catch(errorCallback).finally(finallyCallback);
}

export function sendVerifyCode(api, successCallback, errorCallback = null, finallyCallback = null) {
    axios(api).then(response => {
        successCallback(response.data);
    }).catch(errorCallback).finally(finallyCallback);
}

export function sendRegisterVerifyCode(phone, successCallback, errorCallback = null, finallyCallback = null) {
    let api = getApi("sendRegisterVerifyCode", phone);
    sendVerifyCode(api, successCallback, errorCallback, finallyCallback);
}

export function sendUpdateUserVerifyCode(phone, successCallback, errorCallback = null, finallyCallback = null) {
    let api = getApi("sendUpdateUserVerifyCode", phone);
    sendVerifyCode(api, successCallback, errorCallback, finallyCallback);
}

export function sendResetPasswordVerifyCode(username, successCallback, errorCallback = null, finallyCallback = null) {
    let api = getApi("sendResetPasswordVerifyCode", username);
    axios(api).then(response => {
        successCallback(response.data);
    }).catch(errorCallback).finally(finallyCallback);
}