import { useHistory } from "react-router-dom";
import { Container, Form, Alert, InputGroup, Button, Spinner, Modal } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { sendResetPasswordVerifyCode, resetUserPassword } from "../../api/user";
import { useState } from "react";
import "./index.scss";

// 发送短信间隔，单位秒
const sendVerifyCodePeriod = 60;

export default function ResetPassword(props) {
    let history = useHistory();
    let [remaining, setRemaining] = useState(sendVerifyCodePeriod);
    return (
        <div className="reset-bg">
            <Container className="reset-container">
                <Formik
                    onSubmit={(values, actions) => {
                        resetPassword(values, actions, history);
                    }}
                    validationSchema={formValidation()}
                    initialValues={{
                        username: '',
                        verifyCode: '',
                        newPassword: '',
                    }}
                >
                    {(props) => {
                        return formBody(props, remaining, setRemaining);
                    }}
                </Formik>
            </Container>
        </div>
    )
}

function formValidation() {
    return Yup.object().shape({
        username: Yup
            .string()
            .required('请输入账号')
            .min(6, '账号至少6个字符')
            .max(50, '账号至多50个字符'),
        verifyCode: Yup
            .string()
            .required('请输入验证码'),
        newPassword: Yup
            .string()
            .required('请输入密码')
            .min(8, '密码至少8个字符')
            .max(24, '密码至多24个字符'),
    });
}

function formBody({ values, isSubmitting, touched, validateField, setStatus, status, handleSubmit, handleChange, errors }, remaining, setRemaining) {
    let resetBtn;
    if (isSubmitting) {
        resetBtn = (<>
            <Spinner
                animation="border"
                size="sm"
                style={{
                    marginRight: "5px",
                    display: !isSubmitting ? "none" : "",
                }} />
            <span>重置中</span>
        </>)
    } else {
        resetBtn = <span>确认重置</span>
    }
    let canSendVerifyCode = function () {
        if (isSubmitting || remaining !== sendVerifyCodePeriod) {
            return false;
        }
        validateField('username');
        return !errors.username;
    }
    let sendText = "发送验证码";
    if (remaining !== sendVerifyCodePeriod) {
        sendText = remaining + "秒后发送";
    }
    return (
        <Modal.Dialog className="reset-form">
            <Modal.Header>
                <Modal.Title>重置用户密码</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                    {status && <Alert variant={status.success ? "success" : "danger"}>
                        {status.message}
                    </Alert>
                    }
                    <Form.Group className="mb-3">
                        <Form.Label>账号</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                name="username"
                                value={values.username}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.username && !!errors.username}
                                maxLength="50"
                            />
                            <Button
                                type="button"
                                variant="secondary"
                                disabled={isSubmitting}
                                onClick={() => { canSendVerifyCode() && sendVerifyCode(values.username, remaining, setStatus, setRemaining) }}>
                                {sendText}
                            </Button>
                            <Form.Control.Feedback tooltip type="invalid">
                                {errors.username}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>验证码</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                name="verifyCode"
                                value={values.verifyCode}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.verifyCode && !!errors.verifyCode}
                                maxLength="6"
                            />
                            <Form.Control.Feedback tooltip type="invalid">
                                {errors.verifyCode}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>密码</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="password"
                                name="newPassword"
                                value={values.newPassword}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.newPassword && !!errors.newPassword}
                                maxLength="24"
                            />
                            <Form.Control.Feedback tooltip type="invalid">
                                {errors.newPassword}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <div className="d-grid">
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                            {resetBtn}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal.Dialog>
    )
}

function sendVerifyCode(username, remaining, setStatus, setRemaining) {
    let sec = remaining;
    sendResetPasswordVerifyCode(username, result => {
        setStatus(result);
        if (!result.success) {
            console && console.log(result.message);
            return;
        }
        let timer = setInterval(() => {
            if (sec > 1) {
                setRemaining(--sec);
            } else {
                clearInterval(timer);
                setRemaining(sendVerifyCodePeriod);
            }
        }, 1000);
    });
}

function resetPassword(userInfo, actions, history) {
    resetUserPassword(userInfo, result => {
        actions.setStatus(result);
        if (!result.success) {
            console && console.log(result.message);
            return;
        }
        setTimeout(() => {
            history.push("/login");
        }, 1000);
    }, error => {
        console && console.log(error);
    }, () => {
        actions.setSubmitting(false);
    });
}