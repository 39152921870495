import { Link, useHistory } from "react-router-dom";
import { Container, Form, Alert, InputGroup, Button, Spinner, Modal } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getRootTicket, setLoginUserCookie } from "../../api/cookie";
import { login, getTicketByRootTicket } from "../../api/user";
import "./index.scss";

export default function Login(props) {
    let history = useHistory();
    let rootTicket = getRootTicket();
    if (rootTicket) {
        getTicketByRootTicket(rootTicket, result => {
            let ticket = result.data?.id;
            if (ticket) {
                let redirect = new URLSearchParams(window.location.search).get('redirect');
                redirectAfterLoginSuccess(ticket, history, redirect);
            }
        });
    }
    return render(history, props);
}

function render(history, props) {
    return (
        <div className="login-bg">
            <Container className="login-container">
                <Formik
                    onSubmit={(values, actions) => {
                        loginUser(values, actions, history, props.setUser);
                    }}
                    validationSchema={formValidation()}
                    initialValues={{
                        username: '',
                        password: '',
                        redirect: new URLSearchParams(window.location.search).get('redirect'),
                    }}
                >
                    {(props) => {
                        return formBody(props);
                    }}
                </Formik>
            </Container>
        </div>
    )
}

function formValidation() {
    return Yup.object().shape({
        username: Yup
            .string()
            .required('请输入用户名'),
        password: Yup
            .string()
            .required('请输入密码'),
    });
}

function formBody({ values, isSubmitting, touched, status, handleSubmit, handleChange, errors }) {
    let loginBtn;
    if (isSubmitting) {
        loginBtn = (<>
            <Spinner
                animation="border"
                size="sm"
                style={{
                    marginRight: "5px",
                    display: !isSubmitting ? "none" : "",
                }} />
            <span>登录中</span>
        </>)
    } else {
        loginBtn = <span>登录</span>
    }
    return (
        <Modal.Dialog className="login-form">
            <Modal.Header>
                <Modal.Title>用户登录</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                    {status && !status.success && <Alert variant="danger">
                        {status.message}
                    </Alert>
                    }
                    <Form.Control
                        type="hidden"
                        name="redirect"
                        value={values.redirect ? values.redirect : ''}
                    />
                    <Form.Group className="mb-3">
                        <Form.Label>账号</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                name="username"
                                value={values.username}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.username && !!errors.username}
                            />
                            <Form.Control.Feedback tooltip type="invalid">
                                {errors.username}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>密码</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="password"
                                name="password"
                                value={values.password}
                                readOnly={isSubmitting}
                                onChange={handleChange}
                                isInvalid={touched.password && !!errors.password}
                            />
                            <Form.Control.Feedback tooltip type="invalid">
                                {errors.password}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Text muted>
                            <Link className="reset-password" to="/reset_password">忘记密码？点此重置</Link>
                        </Form.Text>
                    </Form.Group>
                    <div className="d-grid">
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                            {loginBtn}
                        </Button>
                    </div>
                    <Link className="register-entry" to="/register">还没有账号？点这里注册</Link>
                </Form>
            </Modal.Body>
        </Modal.Dialog>
    )
}

function loginUser(values, actions, history, setUser) {
    login(values, result => {
        actions.setStatus(result);
        if (!result.success) {
            console && console.log(result.message);
            return;
        }
        setDateAfterloginSuccess(result.data, setUser)
        redirectAfterLoginSuccess(result.data?.ticket?.id, history, values.redirect);
    }, error => {
        console && console.log(error);
    }, () => {
        setTimeout(() => {
            actions.setSubmitting(false);
        }, 1000);
    });
}

function setDateAfterloginSuccess(data, setUser) {
    setLoginUserCookie(data.rootTicket.id, data.rootTicket.age, data.user);
    setUser(data.user);
}

function redirectAfterLoginSuccess(ticket, history, redirect) {
    try {
        redirect = new URL(redirect);
        let params = new URLSearchParams({ ...redirect.search, ...{ ticket: ticket } });
        redirect.search = params.toString();
        console.log('redirect to', redirect.toString());
        window.location.href = redirect.toString();
    } catch (e) {
        history.push('/home');
    }
}
