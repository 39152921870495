import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { cases, superiority } from "./items";
import { avoidUserCookie } from "../../api/cookie";
import { logout } from "../../api/user";
import "./index.scss";

export default function Home(props) {
    if (props.isLogout) {
        logout(result => {
            avoidUserCookie();
            props.setUser({});
        });
    }
    return (
        <>
            <div className="top-bg">
                <div className="top-bg-content">
                    <div className="title">税筹一站式服务平台</div>
                    <div className="sub-title">更专业、更专注</div>
                    <a className="phone-call" href="tel:4008299665">立即咨询：4008-299-665</a>
                </div>
            </div>
            <div className="content-block cases">
                <Container>
                    <Row xs={1} md={1}>
                        <Col>
                            <div className="content-title">行业解决方案</div>
                        </Col>
                    </Row>
                    <Row xs={1} md={3}>
                        {cases.map((item, i) => (
                            <Col className="content-item" key={"cases" + i}>
                                <Card>
                                    <Card.Img variant="top" src={item.image} />
                                    <Card.Body>
                                        <Card.Title>{item.title}</Card.Title>
                                        <Card.Text>{item.desc}</Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <FontAwesomeIcon icon={faBookmark} className="icon" />
                                        {item.remark}
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
            <div className="content-block superiority">
                <Container>
                    <Row xs={1} md={1}>
                        <Col>
                            <div className="content-title">我们的优势</div>
                            <div className="content-sub-title">专注税务咨询服务，我们更专注</div>
                        </Col>
                    </Row>
                    <Row xs={2} md={4}>
                        {superiority.map((item, i) => (
                            <Col className="content-item" key={"superiority" + i}>
                                <Card>
                                    <div className="card-icon-top">
                                        <i className="iconfont" dangerouslySetInnerHTML={{ __html: item.image }} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title>{item.title}</Card.Title>
                                        <Card.Text>{item.desc}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </>
    )
}