import { useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import useDeepCompareEffect from "use-deep-compare-effect";
import UpdateUserInfo from "./updateUserInfo";
import ChangePassword from "./changePassword";
import { getCookieStoredUserInfo, updateCookieStoredUserInfo, getRootTicket, avoidUserCookie } from "../../api/cookie";
import { logout, getUserInfoByTicket, getUserInfoByRootTicket } from "../../api/user";
import "./index.scss";

function afterGetUser(user, setUser) {
    updateCookieStoredUserInfo(user);
    setUser(user);
}

function logoutUser(setUser) {
    logout(result => {
        avoidUserCookie();
        setUser({});
    });
}

export default function User(props) {
    let [changePasswordModal, changePassword] = useState(false);
    let [updateUserInfoModal, updateUserInfo] = useState(false);

    useDeepCompareEffect(() => {
        let cookieStoredUser = getCookieStoredUserInfo();
        if (cookieStoredUser) {
            console.log('get user info from cookie');
            afterGetUser(cookieStoredUser, props.setUser);
            return;
        }
        let ticket = new URLSearchParams(window.location.search).get('ticket');
        if (ticket) {
            console.log('get user info by ticket');
            getUserInfoByTicket(ticket, result => {
                let user = result?.user;
                user && afterGetUser(user, props.setUser);
            });
            return;
        }
        let rootTicket = getRootTicket();
        if (rootTicket) {
            console.log('get user info by root ticket');
            getUserInfoByRootTicket(rootTicket, result => {
                let user = result?.user;
                user && afterGetUser(user, props.setUser);
            });
            return;
        }
    }, [props.user]);

    if (props.user?.id) {
        return (
            <>
                <NavDropdown title={"欢迎！" + props.user?.name}>
                    <NavDropdown.Item onClick={() => { changePassword(true) }}>修改密码</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { updateUserInfo(true) }}>修改个人信息</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { logoutUser(props.setUser) }}>登出</NavDropdown.Item>
                </NavDropdown>
                <ChangePassword show={changePasswordModal} closeHandler={() => { changePassword(false) }} />
                <UpdateUserInfo show={updateUserInfoModal} closeHandler={() => { updateUserInfo(false) }} />
            </>
        );
    }
    return (
        <>
            <Nav.Link href="#/login">您好，请登录！</Nav.Link>
            <Nav.Link href="#/register">注册</Nav.Link>
        </>
    );
}
