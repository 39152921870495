import { useState } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import Home from "./home/index";
import User from "./user/index";
import Login from "./login/index";
import Register from "./register/index";
import ResetPassword from "./resetPassword/index";
import Agreement from "./agreement/index";
import logo from "../assets/images/logo.png";
import "./app.scss";

export default function App(props) {
    let [user, setUser] = useState({});
    return (
        <Router>
            <div className="header">
                <Navbar bg="light" variant="light">
                    <Container>
                        <Navbar.Brand href="#/home">
                            <img src={logo} className="d-inline-block align-top" height="50" alt="logo" />
                        </Navbar.Brand>
                        <Navbar.Collapse>
                            <Nav>
                                <Nav.Link href="#/home">首页</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        <Navbar>
                            <Nav>
                                <User user={user} setUser={setUser} />
                            </Nav>
                        </Navbar>
                    </Container>
                </Navbar>
            </div>
            <div className="body">
                <Switch>
                    <Route exact path={["/", "/home"]} render={props => (
                        <Home user={user} />
                    )} />
                    <Route exact path="/login" render={props => (
                        <Login setUser={setUser} />
                    )} />
                    <Route exact path="/logout" render={props => (
                        <Home user={user} setUser={setUser} isLogout={true} />
                    )} />
                    <Route exact path="/reset_password" component={ResetPassword} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/agreement" component={Agreement} />
                </Switch>
            </div>
            <div className="footer">
                <span>蜂莲企服版权所有</span>
                <a href="https://beian.miit.gov.cn" target="_blank" rel="noreferrer">沪ICP备19004986号-1</a>
            </div>
        </Router>
    )
}
