import { Container } from "react-bootstrap";
import "./index.scss";

export default function Agreement() {
    return (
        <Container className="agreement-container">

            <div className="title">用户协议</div>
            <p>您注册使用本网站，即代表您同意《用户协议》（简称：协议、本协议）。</p>
            <div className="section">第一条 协议的确认</div>
            <ol>
                <li>本协议（含本协议之附件）有助于贵方了解本网站为贵方提供的服务内容及贵方使用服务的权利和义务，请贵方仔细阅读（特别是以粗体标注的内容）。如对本协议的条款有疑问，贵方可通过本网站的在线客服或客服电话进行咨询。</li>
                <li>贵方同意本公司根据平台经营情况对协议进行变更的权利。<br />如本协议发生实质性变更，本公司将通过网站公告的方式提前予以公布，变更后的协议在3日公告期届满起生效。若贵方在协议生效后继续使用本网站提供的服务，表示贵方接受变更后的协议，本公司也将遵循变更后的协议为贵方提供服务。如果贵方不接受变更后的协议，可以通知公司解除本协议，本公司不承担违约责任。如果贵方不接受协议的实质性变更中涉及服务费、付费方式、违约责任的条款，则贵方也可选择要求公司继续按原上述条款履行协议，直至本服务年度结束，但协议客观上无法履行的除外。如本协议发生非实质性变更（如平台的联系方式等），公司亦将通过网站公告的方式提前予以公布，变更后的协议在3日公告期届满起生效。对于公司非实质性变更协议，贵方无权要求解除本协议，本公司亦不承担违约责任。</li>
                <li>在贵方开始使用本网站服务前，贵方应当具备中华人民共和国法律规定的与贵方行为相适应的民事行为能力。若贵方不具备前述与贵方行为相适应的民事行为能力，则贵方应依照法律规定承担因此而导致的后果。</li>
            </ol>

            <div className="section">第二条 本网站的服务内容</div>
            <p>本网站为互联网企业财税服务平台，我们为贵方提供以下的协助和支持：</p>
            <ol>
                <li>发票托管及管理系统及服务。</li>
                <li>财税咨询服务，包括：财务、税务相关知识与案例。</li>
                <li>公司注册服务，包括：营业执照办理、银行开户、税务核税。</li>
                <li>代记账报税服务，包括：代记账、代报税、代买代开代管发票、代年报公示、代汇算清缴等。</li>
                <li>协助贵方申请税收优惠政策。</li>
            </ol>

            <div className="section">第三条 本网站使用禁止条款</div>
            <p>您理解并同意，我们一直致力于为用户提供文明健康、规范有序网络环境，倡导诚信经营、合法纳税、遵纪守法的基本经营原则，您不得利用本网站帐号、网站或软件及服务制作、复制、发布、传播如下干扰正常运营，以及侵犯其他用户或第三方合法权益的内容，包括但不限于：</p>
            <ol>
                <li>违反宪法确定的基本原则的。</li>
                <li>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的。</li>
                <li>损害国家荣誉和利益的。</li>
                <li>煽动民族仇恨、民族歧视，破坏民族团结的。</li>
                <li>破坏国家宗教政策，宣扬邪教和封建迷信的。</li>
                <li>散布谣言，扰乱社会秩序，破坏社会稳定的。</li>
                <li>散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的。</li>
                <li>侮辱或者诽谤他人，侵害他人合法权益的。</li>
                <li>煽动非法集会、结社、游行、示威、聚众扰乱社会秩序。</li>
                <li>以非法民间组织名义活动的。</li>
                <li>含有法律、行政法规禁止的其他内容的。发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容。</li>
                <li>涉及他人隐私、个人信息或资料的。</li>
                <li>发表、传送、传播骚扰、广告信息及垃圾信息或含有任何性或性暗示的。</li>
                <li>其他违反法律法规、政策及公序良俗、社会公德或干扰正常运营和侵犯其他用户或第三方合法权益内容的信息。</li>
                <li>从事违法经营行为、教唆犯罪、协助犯罪的。</li>
            </ol>

            <div className="section">第四条 重要告知</div>
            <p>您委托本网站办理注册的公司、代理记账的公司、代理注销的公司、代理买票打印发票的公司，需要遵守国家相关法律法规。本网站对您的一切经营行为免责。具体包括：</p>
            <ol>
                <li>您的公司应当按照国家及地方的法律、法规、制度的要求以及本网站的要求，保证经营行为和原始资料的真实、合法、准确、信息清晰和完整，违反本条的将构成本协议的根本违约。</li>
                <li>您的公司应按时支付所有相关的税金或者政府收费，如若存在偷税、逃税、抗税、骗税、欠税、漏税等违法违规行为的，将构成本协议的根本违约。</li>
                <li>您的公司任何违法行为，包括：虚开发票、违法经营等，均与本网站无关。一旦您发生这类行为，将构成本协议的根本违约。</li>
                <li>您在接受服务过程中，需配合政府、银行等机构提供相关人员的身份证原件完成注册服务，并确保注册服务是相关人员的真实意思表示。如若您提供的身份证原件系伪造或骗取、盗窃等非法手段取得他人身份证完成注册服务，注册服务并非相关人员真实意思表示的，将构成本协议的根本违约。涉嫌违法犯罪的，本网站将依法向公安机关等有权机关检举举报，追究您的法律责任。</li>
            </ol>

            <div className="section">第五条 法律适用与管辖</div>
            <p>因本协议产生之争议，均应依照中华人民共和国法律予以处理，任何一方均可向本公司所在地人民法院提起诉讼。</p>

            <div className="section">第六条 同意和确认</div>
            <p>贵方已完全确认和同意以上所有内容，贵方点击“阅读并同意”按钮后，本协议生效。本协议部分内容被认定为无效的，不因此影响其他内容的效力。</p>

        </Container>
    )
}