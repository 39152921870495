import p1 from "../../assets/images/cases/1.jpg";
import p2 from "../../assets/images/cases/2.jpg";
import p3 from "../../assets/images/cases/3.jpg";
import p4 from "../../assets/images/cases/4.jpg";
import p5 from "../../assets/images/cases/5.jpg";
import p6 from "../../assets/images/cases/6.jpg";

export const cases = [
    {
        image: p1,
        title: "行业|个人云工作室",
        desc: "面向个人。自由职业者、网红、自由撰稿人、顾问、培训师、个人店主等",
        remark: "解决个税高和缴社保难的问题",
    },
    {
        image: p2,
        title: "行业|泛建筑业解决方案",
        desc: "面向各类建筑公司、路桥公司、装修公司等。",
        remark: "解决工人费用难和增值税高的问题",
    },
    {
        image: p3,
        title: "行业|新零售阿米巴解决方案",
        desc: "面向连锁型水果店、小吃店、服装、餐饮等",
        remark: "解决门店阿米巴化独立核算和税务合规问题",
    },
    {
        image: p4,
        title: "行业|灵活用工解决方案",
        desc: "面向不定期、经常性使用兼职人员、劳务人员的企业",
        remark: "解决兼职费用难和流动用工风险问题",
    },
    {
        image: p5,
        title: "行业|平台经济解决方案",
        desc: "面向平台型企业，如：直播平台、自媒体平台、打车平台、阅读平台等",
        remark: "解决平台公司的分佣税务合规问题",
    },
    {
        image: p6,
        title: "行业|外地人在上海开公司解决方案",
        desc: "面向连锁型水果店、小吃店、服装、餐饮等",
        remark: "解决跨区域开公司难的问题",
    }
];
export const superiority = [
    {
        image: '&#xe63d;',
        title: "合规&专业",
        desc: "税务、法律专家组共同设计",
    },
    {
        image: '&#xe627;',
        title: "中银税管系统",
        desc: "更专业、更安全",
    },
    {
        image: '&#xe647;',
        title: "简单便捷",
        desc: "全程VIP绿色通道",
    },
    {
        image: '&#xe772;',
        title: "政府园区",
        desc: "与政府园区战略合作",
    }
];
