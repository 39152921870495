import { useState } from "react";
import { Alert, Button, Modal, Form, InputGroup, Spinner } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { changeUserInfo, sendUpdateUserVerifyCode } from "../../api/user";
import { getCookieStoredUserInfo, updateCookieStoredUserInfo } from "../../api/cookie";
import { cloneDeep } from "lodash";

// 发送短信间隔，单位秒
const sendVerifyCodePeriod = 60;

export default function UpdateUserInfo(props) {
    let [remaining, setRemaining] = useState(sendVerifyCodePeriod);
    let cookieStoredUserInfo = getCookieStoredUserInfo();
    return (
        <Modal
            show={props.show}
            onHide={props.closeHandler}
            backdrop="static"
            keyboard={false}
            size="sm"
        >
            <Formik
                onSubmit={(values, actions) => {
                    updateUser(values, actions, props.closeHandler)
                }}
                validationSchema={formValidation()}
                validateOnMount={true}
                initialValues={{ ...cookieStoredUserInfo, verifyCode: '' }}
            >
                {(formikProps) => {
                    return formBody(formikProps, props.closeHandler, remaining, setRemaining);
                }}
            </Formik>
        </Modal>
    )
}

function formValidation() {
    return Yup.object().shape({
        verifyCode: Yup
            .string()
            .optional(),
        email: Yup
            .string()
            .required('请输入邮箱')
            .email('邮箱格式不正确'),
        phone: Yup
            .string()
            .required('请输入手机号')
            .matches(/^1\d{10}/, '手机号格式不正确'),
    });
}

function formBody({ values, initialValues, isSubmitting, touched, setStatus, status, handleSubmit, handleChange, errors }, closeHandler, remaining, setRemaining) {
    let getSubmitBtn = function () {
        let submitBtn;
        if (isSubmitting) {
            submitBtn = (<>
                <Spinner
                    animation="border"
                    size="sm"
                    style={{
                        marginRight: "5px",
                        display: !isSubmitting ? "none" : "",
                    }} />
                <span>修改中</span>
            </>)
        } else {
            submitBtn = <span>确认修改</span>
        }
        return submitBtn;
    }
    let isVerifyCodeDisabled = function () {
        return initialValues.phone === values.phone || !!errors.phone;
    }
    let canSendVerifyCode = function () {
        return !isSubmitting && remaining === sendVerifyCodePeriod && !isVerifyCodeDisabled();
    }
    let getSendText = function () {
        let sendText = "发送验证码";
        if (remaining !== sendVerifyCodePeriod) {
            sendText = remaining + "秒后发送";
        }
        return sendText;
    }
    return (
        <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>修改个人信息</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {status && <Alert variant={status.success ? "success" : "danger"} >
                    {status.message}
                </Alert>
                }
                <Form.Group className="mb-3">
                    <Form.Label>账号名称</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            readOnly
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>邮箱</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            readOnly={isSubmitting}
                            onChange={handleChange}
                            isInvalid={touched.email && !!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>手机号</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="text"
                            name="phone"
                            value={values.phone}
                            readOnly={isSubmitting}
                            onChange={handleChange}
                            isInvalid={touched.phone && !!errors.phone}
                            maxLength="11"
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.phone}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>验证码</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="text"
                            name="verifyCode"
                            value={values.verifyCode}
                            readOnly={isSubmitting}
                            disabled={isVerifyCodeDisabled()}
                            onChange={handleChange}
                            isInvalid={initialValues.phone !== values.phone && touched.verifyCode && !!errors.verifyCode}
                            maxLength="6"
                        />
                        <Button
                            type="button"
                            variant="secondary"
                            disabled={!canSendVerifyCode()}
                            onClick={() => { canSendVerifyCode() && sendVerifyCode(values.phone, remaining, setStatus, setRemaining) }}>
                            {getSendText()}
                        </Button>
                        <Form.Control.Feedback tooltip type="invalid">
                            {errors.verifyCode}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeHandler} disabled={isSubmitting}>取消</Button>
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                    {getSubmitBtn()}
                </Button>
            </Modal.Footer>
        </Form>
    )
}

function updateUser(values, actions, closeHandler) {
    let cookieStoredUser = getCookieStoredUserInfo();
    let userId = cookieStoredUser?.id;
    if (!userId) {
        actions.setSubmitting(false);
        return;
    }
    let data = cloneDeep(values);
    if (data.verifyCode === '') {
        data.verifyCode = undefined;
    }
    changeUserInfo(userId, { ...data, userId: userId }, result => {
        actions.setStatus(result);
        if (!result.success) {
            console && console.log(result.message);
            return;
        }
        let updatedValues = { ...cookieStoredUser, ...values };
        updateCookieStoredUserInfo(updatedValues);
        actions.resetForm({
            values: updatedValues,
            status: result,
        });
        setTimeout(() => {
            closeHandler();
        }, 1000);
    }, error => {
        console && console.log(error);
    }, () => {
        actions.setSubmitting(false);
    });
}

function sendVerifyCode(phone, remaining, setStatus, setRemaining) {
    let sec = remaining;
    sendUpdateUserVerifyCode(phone, result => {
        setStatus(result);
        if (!result.success) {
            console && console.log(result.message);
            return;
        }
        let timer = setInterval(() => {
            if (sec > 1) {
                setRemaining(--sec);
            } else {
                clearInterval(timer);
                setRemaining(sendVerifyCodePeriod);
            }
        }, 1000);
    });
}
